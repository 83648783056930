<template>
  <div v-if="sections" class="info-faq flex flex-col gap-lg">
    <section v-for="{ config, content } in sections" :key="config.name">
      <header class="flex items-center gap-xs pb-md">
        <LcIcon :name="config.icon" />

        <component :is="config.component" class="text-h5">
          {{ $t(`info.faq.section.${config.name}.title`) }}
        </component>
      </header>

      <LcExpandableList>
        <LcExpandable
          v-for="item in content"
          :id="item.sys.id"
          :key="item.sys.id"
          content-class="pt-md"
          :model-value="false"
          variant="enhanced"
        >
          <template #header>{{ item.caption }}</template>

          <ContentfulRenderer class="content" :data="item.content" />
        </LcExpandable>
      </LcExpandableList>
    </section>
  </div>

  <LcNoResults v-else>
    <template #headline>
      {{ $t('info.faq.noresults.headline') }}
    </template>

    <template #subline>
      {{ $t('info.faq.noresults.subline') }}
    </template>
  </LcNoResults>
</template>

<script lang="ts" setup>
import {
  LcExpandable,
  LcExpandableList,
  LcIcon,
  LcNoResults,
} from '@lottocom/frontend-components'

import type { ContentBlock } from '~/@types/generated/cms/graphql-schema-types'
import { INFO_ROUTE_TYPES } from '~/constants/infoRoutes'
import type { InfoPageProps } from '~/custom-pages/info/types'
import { FAQ_COLLECTION_TYPES, getFaqCollectionType } from '~/utils/contentful'

const toFaqBlock = (item: ContentBlock) => ({
  caption: item.caption || '',
  content: item.content,
  sys: { id: item.sys.id },
})

const COLLECTION_CONFIG = {
  [FAQ_COLLECTION_TYPES.PrizesAndWinnings]: {
    component: 'h3',
    icon: 'winning',
    name: 'faqprizesandwinnings',
  },
  [FAQ_COLLECTION_TYPES.TicketsAndDrawings]: {
    component: 'h3',
    icon: 'orders',
    name: 'faqticketsanddrawings',
  },
  [FAQ_COLLECTION_TYPES.TopQuestions]: {
    component: 'h1',
    icon: 'star',
    name: 'faqtopquestions',
  },
} as const

const FETCH_CONFIG = {
  [INFO_ROUTE_TYPES.Scratchcard]: () =>
    useContentfulContent().getFaqScratchcard(props.id),
  [INFO_ROUTE_TYPES.Lottery]: () => useContentfulContent().getFaq(props.id),
} as const

const props = defineProps<InfoPageProps>()

const { data } = await FETCH_CONFIG[props.type]()

const sections = computed(() => {
  if (!data.value) return

  const isCollection = ([key, value]: [string, any]) =>
    key.includes('Collection') && !!value

  return Object.entries(data.value)
    .filter(isCollection)
    .map(([_, value]) => ({
      config: COLLECTION_CONFIG[getFaqCollectionType(value.__typename)],
      content: value!.items.map(toFaqBlock),
    }))
})
</script>

<style lang="scss" scoped>
.info-faq {
  :deep(.content) {
    dl,
    ol,
    ul {
      margin-bottom: spacing('md');
    }

    ol,
    ul {
      padding-left: spacing('md');
      list-style: unset;

      ::marker {
        color: color('secondary600');
      }
    }
  }
}
</style>
